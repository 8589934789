import "../styles/Apropos.scss";
import "lazysizes";

import { graphql, useStaticQuery } from "gatsby";

import React from "react";
import { motion } from "framer-motion";

function Apropos(props) {
  const allData = useStaticQuery(graphql`
    {
      allMarkdownRemark {
        nodes {
          frontmatter {
            aproposTitle
            image
            description
          }
        }
      }
    }
  `).allMarkdownRemark.nodes;
  var aproposData = allData.filter((item) => item.frontmatter.aproposTitle)[0]
    .frontmatter;

  console.log(aproposData);

  return (
    <motion.div
      className="aproposContainer"
      key={2}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <img className="lazyload" data-src={aproposData.image} alt="à propos" />
      <p dangerouslySetInnerHTML={{ __html: aproposData.description }} />
    </motion.div>
  );
}

export default Apropos;
