import "../styles/index.scss";

import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";

import Apropos from "../components/Apropos";
import Contact from "../components/Contact";
import Header from "../components/Header";
import Helmet from "react-helmet";
import Travaux from "../components/Travaux";

const IndexPage = () => {
  const [activeMenu, setActiveMenu] = useState("Travaux");

  const menusTab = ["Travaux", "À propos", "Contact"];

  return (
    <main>
      <Helmet
        title="Yvon Arriudarré | Portfolio"
        htmlAttributes={{ lang: "fr" }}
      />
      <Header
        string="Yvon Arriudarré est un ébéniste et marqueteur Bordelais."
        menusTab={menusTab}
        activeMenu={activeMenu}
        setActiveMenu={setActiveMenu}
      />
      <AnimatePresence exitBeforeEnter>
        {(activeMenu === "Travaux" && (
          <motion.div
            key={1}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Travaux />
          </motion.div>
        )) ||
          (activeMenu === "À propos" && (
            <motion.div
              key={2}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Apropos />
            </motion.div>
          )) ||
          (activeMenu === "Contact" && (
            <motion.div
              key={3}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Contact />
            </motion.div>
          ))}
      </AnimatePresence>
    </main>
  );
};

export default IndexPage;
