import "../styles/Button.scss";

import React from "react";
import { motion } from "framer-motion";

function Button({ active, label, onClick }) {
  return (
    <motion.div
      initial={{ opacity: 0.5 }}
      animate={{ opacity: active ? 1 : 0.5 }}
      className="buttonLi"
      onClick={() => onClick()}
    >
      <motion.div
        initial={{ transform: "translateY(-30%)", opacity: 0 }}
        animate={
          active
            ? {
                transform: "translateY(0%)",
                opacity: 1,
              }
            : {}
        }
        className="underline"
      />
      {label}
    </motion.div>
  );
}

export default Button;
