import "../styles/Travaux.scss";
import "lazysizes";

import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

import Button from "../components/Button";
import _ from "lodash/core";

function Travaux(props) {
  const [activeFilter, setActiveFilter] = useState("Tout");
  const [nowPhotos, setNowPhotos] = useState([]);
  const [nowDescription, setNowDescription] = useState("");
  const [zoomedPic, setZoomedPic] = useState(undefined);
  const [zoomEnabled, setZoomEnabled] = useState(false);

  const allData = useStaticQuery(graphql`
    {
      allMarkdownRemark {
        nodes {
          frontmatter {
            categoriesTitle
            travauxTitle
            image
            categorie
            description
            position
          }
        }
      }
    }
  `).allMarkdownRemark.nodes;

  var filtersTab = allData.filter((item) => item.frontmatter.categoriesTitle);
  filtersTab = _.sortBy(filtersTab, (o) => o.frontmatter.position);

  //UPDATE PHOTOS STATE WHEN ACTIVE FILTER CHANGE
  useEffect(() => {
    let allPhotos = allData.filter((item) => item.frontmatter.categorie);
    let currentFilter = allData.filter(
      (item) => item.frontmatter.categoriesTitle === activeFilter
    );

    if (activeFilter === "Tout") {
      setNowPhotos(allPhotos);
    } else {
      let tempDataPhotos = allPhotos.filter(
        (item) => item.frontmatter.categorie === activeFilter
      );
      setNowPhotos(tempDataPhotos);
    }
    console.log({ currentFilter });
    setNowDescription(currentFilter[0].frontmatter.description);
  }, [activeFilter, allData]);

  return (
    <div className="travauxContainer">
      <AnimatePresence exitBeforeEnter>
        {zoomEnabled && (
          <motion.img
            key={zoomedPic}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            data-src={nowPhotos[zoomedPic].frontmatter.image}
            alt={nowPhotos[zoomedPic].frontmatter.travauxTitle}
            onClick={() => setZoomEnabled(false)}
            className="zoomedPic lazyload"
          />
        )}
        {zoomEnabled && (
          <motion.div
            key={zoomedPic + "bg"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.8 }}
            exit={{ opacity: 0 }}
            onClick={() => setZoomEnabled(false)}
            className="bluredBg"
          />
        )}
      </AnimatePresence>
      <div className="filtersContainer">
        {filtersTab.map((filter, i) => (
          <Button
            key={i}
            label={filter.frontmatter.categoriesTitle}
            active={activeFilter === filter.frontmatter.categoriesTitle}
            onClick={() => {
              setActiveFilter(filter.frontmatter.categoriesTitle);
            }}
          />
        ))}
      </div>
      <div className="contentContainer">
        <AnimatePresence exitBeforeEnter>
          <motion.span
            key={nowDescription}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {nowDescription}
          </motion.span>
        </AnimatePresence>
      </div>
      <div className="grid-container">
        <AnimatePresence exitBeforeEnter>
          {nowPhotos.map((d, i) => (
            <motion.img
              key={d.frontmatter.travauxTitle + i + activeFilter}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: i * 0.1 } }}
              exit={{ opacity: 0, transition: { delay: i * 0.05 } }}
              src={d.frontmatter.image}
              alt={d.frontmatter.travauxTitle}
              onClick={() => {
                setZoomedPic(i);
                setZoomEnabled(true);
              }}
            ></motion.img>
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default Travaux;
