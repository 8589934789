import React from "react";
import { motion } from "framer-motion";

function Titre(props) {
  const homeStringTab = props.string.split(" ");
  // console.log(homeStringTab);
  let prevWordsLength = 0;
  const homeAnimatedSpan = homeStringTab.map((word, i) => {
    let wordTab = word.split("");
    console.log("wordTab[" + i + "]", wordTab);
    if (i !== 0) {
      prevWordsLength += homeStringTab[i - 1].length;
    }
    console.log("prevWordsLength[" + (i - 1) + "]", prevWordsLength);

    return (
      <>
        <span
          key={i}
          style={{
            display: "inline-block",
          }}
        >
          {wordTab.map((letter, j) => {
            return (
              <motion.span
                key={j}
                style={{
                  display: "inline-block",
                  fontWeight: i === 1 || i === 0 ? "bold" : "",
                  color: i === 1 || i === 0 ? "rgb(255, 192, 155)" : "white",
                }}
                initial={{ transform: "translateY(20px)", opacity: 0 }}
                animate={{
                  transform: "translateY(0px)",
                  opacity: 1,
                  transition: {
                    delay: prevWordsLength * 0.05 + j * 0.1,
                    duration: 0.4,
                  },
                }}
              >
                {letter}
              </motion.span>
            );
          })}
        </span>{" "}
      </>
    );
  });
  return homeAnimatedSpan;
}

export default Titre;
