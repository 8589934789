import "../styles/Contact.scss";

import { graphql, useStaticQuery } from "gatsby";

import React from "react";
import { motion } from "framer-motion";

function Contact(props) {
  const allData = useStaticQuery(graphql`
    {
      allMarkdownRemark {
        nodes {
          frontmatter {
            contactTitle
            paragraphe
            mail
            tel
          }
        }
      }
    }
  `).allMarkdownRemark.nodes;
  var contactData = allData.filter((item) => item.frontmatter.contactTitle)[0]
    .frontmatter;

  console.log(contactData);

  return (
    <motion.div
      className="contactContainer"
      key={3}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="paragraphe">{contactData.paragraphe}</div>
      <a
        href={"mailto:" + contactData.mail}
        target="_blank"
        rel="noreferrer"
        className="mail"
      >
        {contactData.mail}
      </a>
      <div className="tel">{contactData.tel}</div>
    </motion.div>
  );
}

export default Contact;
