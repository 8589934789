import "../styles/Header.scss";

import Button from "../components/Button";
import React from "react";
import Titre from "../components/Titre";

function Header({ string, menusTab, activeMenu, setActiveMenu }) {
  return (
    <header>
      <h1>
        <Titre string={string} />
      </h1>
      <nav>
        <div className="navContainer">
          {menusTab.map((menu, i) => (
            <Button
              key={i}
              label={menu}
              active={activeMenu === menu}
              onClick={() => setActiveMenu(menu)}
            />
          ))}
        </div>
      </nav>
    </header>
  );
}

export default Header;
